<template>
  <div>
    <nav-header></nav-header>
    <router-view></router-view>
    <nav-footer
      :phone="this.phone"
      :name="this.name1"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "home",
  components: {
    NavFooter,
    NavHeader,
  },
  methods() {
    document.title = "北京信天易游互动科技有限公司";
  },
  data() {
    return {
      // email: '邮箱：sunchao@spark.art ',
      phone: "电话：010-65210655",
      icp: "备案号：京ICP备15042061号 ",
      name1: "北京信天易游互动科技有限公司",
      addr: "地址：北京市石景山区实兴大街30号院17号楼73号",
    };
  },
};
</script>
<style lang='' scoped>
</style>