import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/home.vue'
import Index from './pages/index.vue'
import Sound from './pages/sound.vue'
import Login from './pages/login.vue'
import Register from './pages/register.vue'
import Pay from './pages/pay.vue'
import Ac1 from './pages/ac1.vue'
import Ac2 from './pages/ac2.vue'
import Ac3 from './pages/ac3.vue'
import Ac4 from './pages/ac4.vue'
import Xy from './pages/xy.vue'
import Info from './pages/info.vue'

Vue.use(Router)

export default new Router({
    routes: [
        // 根路由
        {
            path: '/',
            name: 'home',
            component: Home,
            redirect: '/index',

            children: [
                // 首页
                {
                    path: '/index',
                    name: 'idnex',
                    component: Index,

                },
                // 详情页
                {
                    path: '/sound/:id',
                    name: 'sound',
                    component: Sound,

                },
                // 关于我们
                {
                    path: '/info',
                    name: 'info',
                    component: Info
                },
                // 相关咨询页1
                {
                    path: '/ac1',
                    name: 'ac1',
                    component: Ac1
                },
                // 咨询分页2
                {
                    path: '/ac2',
                    name: 'ac2',
                    component: Ac2
                },
                // 资讯分页3
                {
                    path: '/ac3',
                    name: 'ac3',
                    component: Ac3
                },
                // 资讯分页4
                {
                    path: '/ac4',
                    name: 'ac4',
                    component: Ac4
                },
                // 支付页
                {
                    path: '/pay',
                    name: 'pay',
                    component: Pay,
                },
                // 协议
                {
                    path: '/xy',
                    name: 'xy',
                    component: Xy,
                },
                // 登录
                {
                    path: '/login',
                    name: 'login',
                    component: Login,
                },
                // 注册
                {
                    path: '/register',
                    name: 'register',
                    component: Register,
                },

            ]
        },
    ]
})